var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "govuk-!-margin-bottom-5",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "width": "615",
      "height": "386",
      "viewBox": "0 0 615 386",
      "xmlns": "http://www.w3.org/2000/svg",
      "aria-labelledby": "svg-title",
      "aria-describedby": "svg-desc",
      "role": "img"
    }
  }, [_c('title', {
    attrs: {
      "id": "svg-title"
    }
  }, [_vm._v("Operational energy efficiency chart")]), _c('desc', {
    attrs: {
      "id": "svg-desc"
    }
  }, [_vm._v("This building’s operational energy rating is " + _vm._s(_vm.assetRatingBand) + " with a score of " + _vm._s(_vm.assetRating) + ".")]), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "72",
      "y1": "0",
      "x2": "72",
      "y2": "386"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "410",
      "y1": "0",
      "x2": "410",
      "y2": "386"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "530",
      "y1": "0",
      "x2": "530",
      "y2": "386"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "0",
      "y1": "25",
      "x2": "615",
      "y2": "25"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "0",
      "y1": "0",
      "x2": "615",
      "y2": "0"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "0",
      "y1": "0",
      "x2": "0",
      "y2": "386"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "615",
      "y1": "386",
      "x2": "615",
      "y2": "0"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "#b1b4b6",
      "stroke-width": "1"
    },
    attrs: {
      "x1": "615",
      "y1": "386",
      "x2": "0",
      "y2": "386"
    }
  }), _c('rect', {
    staticClass: "band-a",
    attrs: {
      "width": "78",
      "height": "50",
      "x": "72",
      "y": "25"
    }
  }), _c('rect', {
    staticClass: "band-b",
    attrs: {
      "width": "118",
      "height": "50",
      "x": "72",
      "y": "75"
    }
  }), _c('rect', {
    staticClass: "band-c",
    attrs: {
      "width": "158",
      "height": "50",
      "x": "72",
      "y": "125"
    }
  }), _c('rect', {
    staticClass: "band-d",
    attrs: {
      "width": "198",
      "height": "50",
      "x": "72",
      "y": "175"
    }
  }), _c('rect', {
    staticClass: "band-e",
    attrs: {
      "width": "250.0",
      "height": "50",
      "x": "72",
      "y": "240.0"
    }
  }), _c('rect', {
    staticClass: "band-f",
    attrs: {
      "width": "286.0",
      "height": "50",
      "x": "72",
      "y": "285.0"
    }
  }), _c('rect', {
    staticClass: "band-g",
    attrs: {
      "width": "326.0",
      "height": "50",
      "x": "72",
      "y": "335.0"
    }
  }), _c('rect', {
    staticClass: "band-a-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "25"
    }
  }), _c('rect', {
    staticClass: "band-b-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "75"
    }
  }), _c('rect', {
    staticClass: "band-c-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "125"
    }
  }), _c('rect', {
    staticClass: "band-d-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "175"
    }
  }), _c('rect', {
    staticClass: "band-e-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "240.0"
    }
  }), _c('rect', {
    staticClass: "band-f-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "285.0"
    }
  }), _c('rect', {
    staticClass: "band-g-score",
    attrs: {
      "width": "72",
      "height": "50",
      "x": "0",
      "y": "335.0"
    }
  }), _c('text', {
    staticClass: "letter",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "0",
      "y": "0"
    }
  }, [_c('tspan', {
    attrs: {
      "x": "107",
      "y": "64"
    }
  }, [_vm._v("A")]), _c('tspan', {
    attrs: {
      "x": "147",
      "y": "114"
    }
  }, [_vm._v("B")]), _c('tspan', {
    attrs: {
      "x": "187",
      "y": "164"
    }
  }, [_vm._v("C")]), _c('tspan', {
    attrs: {
      "x": "227",
      "y": "214"
    }
  }, [_vm._v("D")]), _c('tspan', {
    attrs: {
      "x": "279.0",
      "y": "279.0"
    }
  }, [_vm._v("E")]), _c('tspan', {
    attrs: {
      "x": "315.0",
      "y": "324.0"
    }
  }, [_vm._v("F")]), _c('tspan', {
    attrs: {
      "x": "355.0",
      "y": "374.0"
    }
  }, [_vm._v("G")])]), _c('text', {
    staticClass: "small",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "0",
      "y": "0"
    }
  }, [_c('tspan', {
    attrs: {
      "x": "8",
      "y": "55"
    }
  }, [_vm._v("0-25")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "105"
    }
  }, [_vm._v("26-50")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "155"
    }
  }, [_vm._v("51-75")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "205"
    }
  }, [_vm._v("76-100")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "270.0"
    }
  }, [_vm._v("101-125")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "315.0"
    }
  }, [_vm._v("126-150")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "365.0"
    }
  }, [_vm._v("150+")])]), _c('text', {
    staticClass: "small",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "8",
      "y": "15",
      "dominant-baseline": "middle"
    }
  }, [_vm._v("Score")]), _c('text', {
    staticClass: "small",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "85",
      "y": "15",
      "dominant-baseline": "middle"
    }
  }, [_vm._v("Operational rating")]), _c('text', {
    staticClass: "small",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "470",
      "y": "15",
      "text-anchor": "middle",
      "dominant-baseline": "middle"
    }
  }, [_vm._v("This building")]), _c('text', {
    staticClass: "small",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "570",
      "y": "15",
      "text-anchor": "middle",
      "dominant-baseline": "middle"
    }
  }, [_vm._v("Typical")]), _c('line', {
    staticStyle: {
      "stroke": "black",
      "stroke-width": "2"
    },
    attrs: {
      "x1": "0",
      "y1": "235",
      "x2": "558",
      "y2": "235",
      "stroke-dasharray": "3,3"
    }
  }), _c('line', {
    staticStyle: {
      "stroke": "black",
      "stroke-width": "2"
    },
    attrs: {
      "x1": "625",
      "y1": "235",
      "x2": "598",
      "y2": "235",
      "stroke-dasharray": "3,3"
    }
  }), _c('text', {
    staticClass: "typical",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "0",
      "y": "0"
    }
  }, [_c('tspan', {
    attrs: {
      "x": "560",
      "y": "240"
    }
  }, [_vm._v("100")])]), _c('svg', {
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "420",
      "y": _vm.ratingPosition,
      "width": "90",
      "height": "50"
    }
  }, [_c('rect', {
    class: _vm.decColourStatus,
    attrs: {
      "width": "75",
      "height": "50",
      "x": "25"
    }
  }), _c('polygon', {
    class: _vm.decColourStatus,
    attrs: {
      "points": "0,25 25,50 25,0 0,25"
    }
  }), _c('text', {
    staticClass: "govuk-!-font-weight-bold",
    attrs: {
      "x": "60%",
      "y": "50%",
      "dominant-baseline": "middle",
      "text-anchor": "middle"
    }
  }, [_vm._v(" " + _vm._s(_vm.assetRating) + " | " + _vm._s(_vm.assetRatingBand) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }