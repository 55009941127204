var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.certificate.certificate ? _c('div', {
    attrs: {
      "data-cy": "asset-certificate-view"
    }
  }, [_vm.certificate.certificate.type === 'epc' ? _c('EpcCertificate', {
    attrs: {
      "certificate": _vm.certificate.certificate
    }
  }) : _vm.certificate.type === 'dec' ? _c('DecCertificate', {
    attrs: {
      "certificate": _vm.certificate.certificate
    }
  }) : _c('BreeamCertificate', {
    attrs: {
      "certificate": _vm.certificate.certificate
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }